.home {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.headerContainer {
  width: auto;
  margin-left: 30px;
}

.home .headerContainer h1 {
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 70px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  color: black;
}

.home .headerContainer p {
  margin-top: 15px;
  padding: 0;
  font-size: 40px;
  font-weight: lighter;
  color: black;
}

.headerContainer button {
  background-color: #121619;
  margin-top: 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  height: 50px;
  width: 180px;
  text-decoration: none;
  color: white;
  font-size: 20px;
}

.headerContainer button:hover {
  background-color: #07090a;
  cursor: pointer;
  transition: 0.3s ease-in;
}

/* mobile view  */

@media only screen and (max-width: 600px) {
  .home {
    justify-content: center;
    align-items: center;
  }
  .headerContainer {
    margin-left: 0;
    border-radius: 10px;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #121619;
    color: white;
  }
  .home .headerContainer h1 {
    font-size: 40px;
    height: 30px;
    margin: 30px 0px;
    color: white;
  }
  .home .headerContainer p {
    font-size: 30px;
    color: white;
  }
  .headerContainer button {
    background-color: white;
    color: #121619;
    margin-bottom: 30px;
  }
}
